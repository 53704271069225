import React from 'react'

// components
import PageWrap from '../Global/PageWrap'
import Mantel from '../../General/Mantel'
import TabNav from '../../General/TabNav'

const TabbedPage = ({ seo, bg = 1, mantelData, tabs, children }) => {
  return (
    <PageWrap bg={bg} seo={seo}>
      <div>
        <Mantel {...mantelData} />
        <TabNav items={tabs} />
        <div className="max-w-3xl mx-auto pb-10">{children}</div>
      </div>
    </PageWrap>
  )
}

export default TabbedPage
