import React from 'react'
import { graphql } from 'gatsby'
import TabbedPage from '../components/Layouts/TabbedPage'
import { extract } from '../utils/graphql'
import { localizePath } from '../../config/i18n'

import NoTranslation from '../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'
import { Section } from './standards-proposals'

const Page = ({
  data: {
    page,
    researchInnovation,
    researchInnovationEnglish,
    prismicStandardsProposals,
  },
  pageContext,
}) => {
  if (!page) return <NoTranslation></NoTranslation>

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      parent: {
        path: localizePath(pageContext.locale, '/resources'),
        text: 'Resources',
      },
    }),
    tabs: [
      {
        active: false,
        url: localizePath(pageContext.locale, '/publications/'),
        text:
          researchInnovation.data.tab_text.text ||
          researchInnovationEnglish.data.tab_text.text,
      },
      {
        active: true,
        url: localizePath(pageContext.locale, '/peer-reviewed-papers/'),
        text: page.data.tab_text.text,
      },
      {
        active: false,
        url: localizePath(pageContext.locale, '/standards-proposals/'),
        text: prismicStandardsProposals
          ? prismicStandardsProposals.data.tab_text.text
          : 'Standards Proposals',
      },
    ],
  }

  return (
    <TabbedPage {...hydratedData}>
      {page.data.body.map((section) => (
        <Section
          key={section.primary.research_papers_section_title.text}
          section={section}
        />
      ))}
    </TabbedPage>
  )
}

export default withPreview(Page)

export const pageQuery = graphql`
  query($locale: String!) {
    researchInnovationEnglish: prismicResearchInnovation(
      lang: { eq: "en-us" }
    ) {
      data {
        tab_text {
          text
        }
      }
    }
    researchInnovation: prismicResearchInnovation(lang: { eq: $locale }) {
      data {
        tab_text {
          text
        }
      }
    }
    prismicStandardsProposals(lang: { eq: $locale }) {
      data {
        tab_text {
          text
        }
      }
    }
    page: prismicTeamResearchPapers(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        mantel_content {
          html
        }
        tab_text {
          text
        }
        body {
          ... on PrismicTeamResearchPapersBodyResearchPapersSection {
            primary {
              research_papers_section_title {
                text
              }
            }
            items {
              research_paper_title {
                text
              }
              research_paper_author_name {
                text
              }
              research_paper_description {
                html
              }
              research_paper_link {
                url
              }
              research_paper_date
            }
          }
        }
      }
    }
  }
`
